import React from 'react'
import PropTypes from 'prop-types'
import BaseCard from 'src/components/Cards/BaseCard'
import { Heading, Wysiwyg } from 'src/components/Base'
import ReadMoreLink from 'src/components/ReadMoreLink'
import { cNewsCard, cExcerpt, cReadmore, cHeading } from './styles.module.scss'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const NewsCard = ({ title, link, excerpt, date, image, hasBackground }) => (
  <BaseCard
    className={cNewsCard}
    backgroundColor={(hasBackground && '#FFFFFF') || '#F7FAFC'}
    headerHasNoPadding
    header={
      image && (
        <GatsbyImage
          layout="fullWidth"
          alt={image.altText}
          image={getImage(image.localFile.childImageSharp)}
        />
      )
    }
    body={
      <>
        <Heading size={4} as="h3" color="dark" className={cHeading}>
          {title}
        </Heading>
        <Wysiwyg className={cExcerpt}>{excerpt}</Wysiwyg>
        <ReadMoreLink link={link} className={cReadmore} />
      </>
    }
    date={date}
    hasBorder
    isStretched
  />
)

NewsCard.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  excerpt: PropTypes.string,
  date: PropTypes.string.isRequired,
  image: PropTypes.object,
}

NewsCard.defaultProps = {
  excerpt: '',
  image: undefined,
}

export default NewsCard
