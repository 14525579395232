import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Heading, Section } from 'src/components/Base'
import NewsCard from 'src/components/Cards/NewsCard'
import Pagination from 'src/components/Pagination'
import LanguageContext from 'src/context/language'
import { scrollToTop } from 'src/utils/helper.js'
import { v4 as uuid } from 'uuid'

import { cButtonWrapper, cNewsCol, cNewsGrid } from './styles.module.scss'

const NewsGrid = ({
  posts,
  heading,
  allNewsButton,
  hasBackground,
  postsPerPage,
  branchColor,
  hasPagination,
  maxPosts,
}) => {
  const containerUUID = 'news-grid-container'
  const languageContext = useContext(LanguageContext)

  const checkLocale = `${languageContext.currentLangauge}_${languageContext.currentLocation}`

  // We create a deep copy of our post-array to work with
  let languagePostsUnfiltered = posts ? JSON.parse(JSON.stringify(posts)) : []

  languagePostsUnfiltered = languagePostsUnfiltered.sort((a, b) => {
    let dateA = a.atspNews.newsDate
    let dateB = b.atspNews.newsDate
    // switch format so Date.parse can read it
    dateA = Date.parse(dateA.split('.').reverse().join('-'))
    dateB = Date.parse(dateB.split('.').reverse().join('-'))
    // to sort in ascending order switch dateA with dateB
    return dateB - dateA
  })

  languagePostsUnfiltered.forEach((post) => {
    // At first, we check, if this post can be used as default and is Austrian
    // If a post can be copied and is Austrian, it is a candidate to be copied
    if (
      post.atspLanguage?.doNotUseAsDefault !== true &&
      post.language.locale === 'de_AT'
    ) {
      // But we can only duplicate the default post if there is no other post for this one already created by the user
      // We do this by checking the locale and the slug. We assume, that the slug always is the same for the language.
      // This assumption is agreed upon by the customer
      // If there is no post already here, we create a new one and change the language features
      if (
        languagePostsUnfiltered.findIndex(
          (lp) => lp.language.locale === 'de_CH' && lp.slug === post.slug
        ) === -1
      ) {
        let postCH = JSON.parse(JSON.stringify(post))
        postCH.language.locale = 'de_CH'
        postCH.link = postCH.link.replace('de_at', 'de_ch')
        languagePostsUnfiltered.push(postCH)
      }

      if (
        languagePostsUnfiltered.findIndex(
          (lp) => lp.language.locale === 'de_DE' && lp.slug === post.slug
        ) === -1
      ) {
        let postDE = JSON.parse(JSON.stringify(post))
        postDE.language.locale = 'de_DE'
        postDE.link = postDE.link.replace('de_at', 'de_de')
        languagePostsUnfiltered.push(postDE)
      }
    }
  })

  let languagePosts = languagePostsUnfiltered.filter((newsSingle) => {
    return (
      newsSingle.language.locale.toLowerCase() === checkLocale.toLowerCase()
    )
  })

  if (maxPosts > 0) {
    languagePosts = languagePosts.slice(0, maxPosts)
  }

  const [postsToDisplay, setPostsToDisplay] = useState([])
  let showPagination = languagePosts.length > postsPerPage

  useEffect(() => {
    if (showPagination) {
      setPostsToDisplay(languagePosts.slice(0, postsPerPage))
    } else {
      setPostsToDisplay(languagePosts)
    }
  }, [languageContext])

  const [currentPage, setCurrentPage] = useState(1)
  const numPages = Math.ceil(languagePosts.length / postsPerPage)

  const paginate = (page) => {
    let skip = postsPerPage * page - postsPerPage
    setPostsToDisplay(languagePosts.slice(skip, skip + postsPerPage))
    scrollToTop()
    setCurrentPage(page)
  }

  return (
    <>
      {postsToDisplay.length > 0 && (
        <Section id={containerUUID} hasBackground={hasBackground}>
          {heading && (
            <Heading
              as="h2"
              size={2}
              color="dark"
              isCentered
              branchColor={branchColor}
            >
              {heading}
            </Heading>
          )}
          <div className={cNewsGrid}>
            {postsToDisplay &&
              postsToDisplay.map(({ title, slug, excerpt, atspNews }) => (
                <div key={uuid()} className={cNewsCol}>
                  <NewsCard
                    title={title}
                    link={`/news/${slug}`}
                    excerpt={excerpt}
                    date={atspNews.newsDate}
                    image={atspNews.newsImageTeaser}
                    hasBackground={hasBackground}
                  />
                </div>
              ))}
          </div>
          {allNewsButton && (
            <div className={cButtonWrapper}>
              <Button
                as="a"
                href={`/${languageContext.currentLangauge}-${languageContext.currentLocation}${allNewsButton.url}`}
                color="cta"
              >
                {allNewsButton.title}
              </Button>
            </div>
          )}
          {hasPagination && showPagination && numPages > 1 && (
            <Pagination
              onPageChange={paginate}
              numPages={numPages}
              activePage={currentPage}
            />
          )}
        </Section>
      )}
    </>
  )
}

NewsGrid.defaultProps = {
  heading: undefined,
  allNewsButton: undefined,
  hasBackground: false,
  postsPerPage: 3,
  hasPagination: true,
  maxPosts: -1,
}

NewsGrid.propTypes = {
  posts: PropTypes.array.isRequired,
  heading: PropTypes.string,
  allNewsButton: PropTypes.object,
  hasPagination: PropTypes.bool,
  hasBackground: PropTypes.bool,
  postsPerPage: PropTypes.number,
  maxPosts: PropTypes.number,
}

export default NewsGrid
