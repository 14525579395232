import React from 'react'
import PropTypes from 'prop-types'
import {
  cPagination,
  cChevronLeft,
  cChevronRight,
  cPaginateButtonLeft,
  cPaginateButtonRight,
} from './styles.module.scss'
import { Button, Text } from 'src/components/Base'
import ChevronIcon from 'src/assets/images/chevron-right-solid.inline.svg'
import classNames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'

const Pagination = ({ numPages, activePage, onPageChange }) => {
  const paginationTranslations = useStaticQuery(graphql`
    query paginationTranslationQuery {
      allWp {
        nodes {
          themeGeneralSettings {
            atspCustomStringTranslations {
              translationPagination {
                txtFrom
                txtPage
              }
            }
          }
        }
      }
    }
  `)

  const next = () => {
    onPageChange(activePage + 1)
  }

  const prev = () => {
    onPageChange(activePage - 1)
  }

  return (
    <div className={cPagination}>
      {activePage !== 1 && (
        <Button
          lessPadding
          noMinWidth
          className={classNames(cPaginateButtonLeft)}
          color="cta"
          onClick={() => prev()}
        >
          <ChevronIcon className={cChevronLeft} />
        </Button>
      )}

      <Text as="span">
        {
          paginationTranslations.allWp.nodes[0].themeGeneralSettings
            .atspCustomStringTranslations.translationPagination.txtPage
        }
        <strong> {activePage} </strong>
        {
          paginationTranslations.allWp.nodes[0].themeGeneralSettings
            .atspCustomStringTranslations.translationPagination.txtFrom
        }
        <strong> {numPages}</strong>
      </Text>
      {activePage !== numPages && (
        <Button
          lessPadding
          noMinWidth
          className={classNames(cPaginateButtonRight)}
          color="cta"
          onClick={() => next()}
        >
          <ChevronIcon className={cChevronRight} />
        </Button>
      )}
    </div>
  )
}

Pagination.PropTypes = {
  numPages: PropTypes.string.isRequred,
  activePage: PropTypes.string.isRequred,
}

export default Pagination
