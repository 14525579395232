import React from 'react'
import { graphql } from 'gatsby'
import { Hero, AboutUs, Logos, Partners } from 'src/sections'
import { Heading, Section, Wysiwyg, Container } from 'src/components/Base'
import SectionTwoColumns from 'src/components/SectionTwoColumns'
import NewsGrid from 'src/components/NewsGrid'
import BaseCard from 'src/components/Cards/BaseCard'
import { GatsbyImage } from 'gatsby-plugin-image'
import Seo from 'src/components/Seo'
import {
  cAtspEngagementFirst,
  cAtspEngagementLast,
  cAtspEngagement,
  cTwoColumnImage,
} from 'src/templates/about-us-company/styles.module.scss'

const AboutUsCompany = ({ data }) => {
  const page = data.wpPage
  const hero = page.atspHero
  const aboutUsCompany = page.atspAboutUsCompany
  const aboutUsFiftyfifty = aboutUsCompany.companyAboutFiftyfifty
  const intro = aboutUsCompany.companyAboutIntro
  const aboutFacts = aboutUsCompany.companyAboutFacts
  const aboutFactsHeading = aboutFacts.heading
  const companyFacts = (
    <BaseCard
      hasBorder
      backgroundColor="#F7FAFC"
      body={<Wysiwyg>{aboutFacts.factsOverview.facts}</Wysiwyg>}
      isStretched
    />
  )
  const aboutFactsImage = (
    <GatsbyImage
      image={
        aboutFacts.factsOverview.image.localFile.childImageSharp.gatsbyImageData
      }
      alt=""
    />
  )
  const newsHeading = aboutUsCompany.companyAboutNews.heading
  // Important Things SectionTwoColumns
  const importantThings = aboutUsCompany.companyAboutImportantThings
  const importantThingsHeading = importantThings.heading
  const engagementImage = (
    <GatsbyImage
      className={cTwoColumnImage}
      image={
        importantThings.importantThingsOverview[0].importantThingsImage
          .localFile.childImageSharp.gatsbyImageData
      }
      alt=""
    />
  )
  const engagementText = (
    <BaseCard
      hasBorder
      isStretched
      backgroundColor="#F7FAFC"
      body={
        <Wysiwyg>
          {importantThings.importantThingsOverview[0].importantThingsCard.text}
        </Wysiwyg>
      }
    />
  )
  const accessibilityImage = (
    <GatsbyImage
      className={cTwoColumnImage}
      image={
        importantThings.importantThingsOverview[1].importantThingsImage
          .localFile.childImageSharp.gatsbyImageData
      }
      alt=""
    />
  )
  const accessibilityText = (
    <BaseCard
      hasBorder
      isStretched
      backgroundColor="#F7FAFC"
      body={
        <Wysiwyg>
          {importantThings.importantThingsOverview[1].importantThingsCard.text}
        </Wysiwyg>
      }
    />
  )
  const greenCompanyImage = (
    <GatsbyImage
      className={cTwoColumnImage}
      image={
        importantThings.importantThingsOverview[2].importantThingsImage
          .localFile.childImageSharp.gatsbyImageData
      }
      alt=""
    />
  )
  const greenCompanyText = (
    <BaseCard
      hasBorder
      isStretched
      backgroundColor="#F7FAFC"
      body={
        <Wysiwyg>
          {importantThings.importantThingsOverview[2].importantThingsCard.text}
        </Wysiwyg>
      }
    />
  )

  const aboutUsContact = page.atspContact.contactfiftyfifty
  const contactHeader = aboutUsContact.heading
  const contactText = aboutUsContact.text
  const contactButton = page.atspContact.contactButton
  const aboutAwards = aboutUsCompany.companyAboutAwards
  const aboutUsPartners = aboutUsCompany.companyAboutPartners

  return (
    <>
      <Seo
        title={page.seo.title}
        locale={page.language.locale}
        description={page.seo.metaDesc}
      />
      <Hero imageData={hero.heroImage.localFile} heading={hero.heroHeading} />
      <Section>
        <Heading isCentered color="dark" as="h2" size={2}>
          {intro.heading}
        </Heading>
        <Container>
          <Wysiwyg isCentered>{intro.text}</Wysiwyg>
        </Container>
      </Section>
      <AboutUs
        heading={aboutUsFiftyfifty.heading}
        text={aboutUsFiftyfifty.text}
        hasBackground
      />
      <SectionTwoColumns
        heading={aboutFactsHeading}
        introText={aboutFacts.intro}
        headingColor="dark"
        contentLeft={companyFacts}
        contentRight={aboutFactsImage}
        reorderMobile
      />
      <NewsGrid
        heading={newsHeading}
        hasBackground
        posts={data.allWpNeuigkeit.nodes}
        postsPerPage={3}
        maxPosts={3}
      />
      <SectionTwoColumns
        heading={importantThingsHeading}
        headingColor="dark"
        contentRight={engagementText}
        contentLeft={engagementImage}
        className={cAtspEngagementFirst}
      />
      <SectionTwoColumns
        contentRight={accessibilityImage}
        contentLeft={accessibilityText}
        reorderMobile
        className={cAtspEngagement}
      />
      <SectionTwoColumns
        contentRight={greenCompanyText}
        contentLeft={greenCompanyImage}
        className={cAtspEngagementLast}
      />
      <Logos heading={aboutAwards.heading} logos={aboutAwards.logos} />
      <SectionTwoColumns
        centerMobile
        contentLeft={
          <Heading as="h2" color="dark" size={2}>
            {contactHeader}
          </Heading>
        }
        contentRight={<Wysiwyg>{contactText}</Wysiwyg>}
        rightColumnRemoveDefaultMarginOnMobile
        buttonRight
        buttonText={contactButton.text}
        buttonLink={contactButton.link.link}
        hasDivider
      />
      <Partners
        heading={aboutUsPartners.heading}
        partnerRepeater={aboutUsPartners.partnersCardRepeater}
        labels={aboutUsPartners.partnersCardLabels}
        linkText={aboutUsPartners.linkText}
      />
    </>
  )
}

export const pageQuery = graphql`
  query AboutUsCompanyById($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      language {
        locale
      }
      seo {
        title
        metaDesc
      }
      content
      atspHero {
        heroHeading
        heroImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, width: 2880, quality: 90)
            }
          }
        }
      }
      atspAboutUsCompany {
        fieldGroupName
        companyAboutPartners {
          heading
          linkText
          partnersCardLabels {
            areaOfPartnership
            markets
            origin
            partnerSince
          }
          partnersCardRepeater {
            heading
            logo {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                url
              }
            }
            partnerDetails {
              expertise
              founded
              headquarters
              markets
              linkLocation {
                target
                title
                url
              }
            }
          }
        }
        companyAboutNews {
          heading
        }
        companyAboutIntro {
          heading
          text
        }
        companyAboutImportantThings {
          heading
          importantThingsOverview {
            importantThingsCard {
              text
            }
            importantThingsImage {
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 510, height: 350)
                }
              }
            }
          }
        }
        companyAboutFiftyfifty {
          heading
          text
        }
        companyAboutFacts {
          heading
          intro
          factsOverview {
            facts
            image {
              localFile {
                url
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
        companyAboutAwards {
          heading
          logos {
            image {
              altText
              localFile {
                url
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
      atspContact {
        contactButton {
          text
          link {
            ... on WpPage {
              link
            }
          }
        }
        contactfiftyfifty {
          heading
          text
        }
      }
    }
    allWpNeuigkeit(sort: { order: DESC, fields: atspNews___newsDate }) {
      nodes {
        atspNews {
          newsDate
          newsImageTeaser {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        title
        link
        slug
        excerpt
        language {
          locale
        }
      }
    }
  }
`

export default AboutUsCompany
